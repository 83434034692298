import React from 'react'

const Loader = () => {
  return (
    <section className='loader__inner'>
    <div className="loader" />
  </section>
  
  )
}

export default Loader